<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailForm" title="작업요청 정보">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="결재요청" icon="check" color="purple" />
            <c-btn v-if="editable&&!disabled" label="삭제" icon="delete_forever" />
            <c-btn v-if="editable&&!disabled" label="저장" icon="save" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="false"
              label="작업지시No."
              name="col0"
              v-model="data.col0">
            </c-text>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="작업명"
              name="col1"
              v-model="data.col1">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :required="true"
              :editable="editable"
              type="edit"
              :comboItems="comboItems1"
              itemText="codeName"
              itemValue="code"
              name="col4"
              label="작업유형"
              v-model="data.col4"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              :editable="false"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-equip
              :required="true"
              label="대상설비"
              name="col6"
              v-model="data.col6">
            </c-equip>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-dept type="edit" :editable="editable" label="위치" name="col5" v-model="data.col5" :isFirstValue="false" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-dept type="edit" :editable="false" label="작업요청부서" name="col3" v-model="data.col3" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="false"
              label="작업요청자"
              name="col19"
              v-model="data.col19">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="false"
              default="today"
              label="요청 종료일"
              name="col2"
              v-model="data.col2"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-checkbox
              :editable="editable && dataeditable"
              :isFlag="true"
              label="안전작업허가서 발행여부"
              name="col99"
              v-model="data.col99"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-textarea
              :editable="editable"
              :rows="4"
              label="작업요청 세부사항"
              name="col7"
              v-model="data.col7">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="padding-top:13px !important;">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable"
              label="대상설비 도면 등 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-mobile-table
        ref="grid"
        title="세부 작업계획"
        :columns="grid.columns"
        :data="grid.data"
        :gridHeightAuto="true"
        :isTitle="true"
        :editable="editable&&!disabled"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="userId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="" icon="add"/>
            <c-btn v-show="editable&&!disabled" label="안전작업표준추가" icon="add" @btnClicked="openSop" />
            <c-btn v-if="editable&&!disabled" label="" icon="remove" />
          </q-btn-group>
        </template>
      </c-mobile-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card class="cardClassDetailForm" title="작업 스케줄정보" style="height: 252px !important">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="editable"
              type="datetime"
              label="목표 시작일시"
              name="col002"
              :minuteStep="10"
              v-model="data.col002"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="editable"
              type="datetime"
              label="목표 완료일시"
              name="col003"
              :minuteStep="10"
              v-model="data.col003"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="false"
              type="datetime"
              label="실제 시작일시"
              name="col004"
              :minuteStep="10"
              v-model="data.col004"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="false"
              type="datetime"
              label="실제 완료일시"
              name="col005"
              :minuteStep="10"
              v-model="data.col005"
            />
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      comboItems1: [
        {
          code: '1',
          codeName: 'PM(예방보전)',
        },
        {
          code: '2',
          codeName: 'RM(일상보전)',
        },
        {
          code: '3',
          codeName: 'CM(개량보전)',
        },
        {
          code: '4',
          codeName: 'BM(사후보전)',
        },
        {
          code: '5',
          codeName: 'PdM(예측보전)',
        },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OUTER_AUDIT_ATTACH',
        taskKey: '',
      },
      editable: true,
      data: {
        plantCd: null,
        col0: 'WO-02-001',
        col1: '지하저장조 배관 변경작업',
        col2: '2022-01-01',
        col3: '',
        col4: '2',
        col5: null,
        col6: 'EC00000001',
        col7: '지하저장조 배관 변경작업을 위한 부품교체작업 필요',
        col8: '',
        col9: '',
        col10: '',
        col001: 'N',
        col002: '2022-11-22 10:10:00',
        col003: '2022-11-22 13:40:00',
      },
      grid: {
        // merge: [
        //   { index: 0, colName: 'processCd' },
        //   { index: 1, colName: 'groupMdmSopId' },
        //   { index: 2, colName: 'groupJobStepId' },
        // ],
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '작업단계',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            sortable: false,
            colClass: 8,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '작업시간',
            align: 'center',
            style: 'width:100px',
            type: 'datetime',
            datetimeType: 'time', 
            sortable: false,
            colClass: 4,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업 상세',
            type: 'text',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '253px'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid.data = [
        {
          col1: '배관 용접 작업',
          col2: '현장 설비 확인 및 안전작업 허가서 확인',
          col3: '01:00',
        },
        {
          col1: '자재 및 공구준비',
          col2: '철골 데크 플레이트 설치',
          col3: '02:30',
        },
      ]
    },
    openSop() {      
      this.popupOptions.title = '안전작업표준(SOP) 검색'; // 안전작업표준(SOP) 검색
      this.popupOptions.param = {
        type: 'multiple',
        searchType: '2', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        subProcessCd: null
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>