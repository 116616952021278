var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "작업요청 정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "결재요청",
                              icon: "check",
                              color: "purple",
                            },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "delete_forever" },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: false,
                        label: "작업지시No.",
                        name: "col0",
                      },
                      model: {
                        value: _vm.data.col0,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col0", $$v)
                        },
                        expression: "data.col0",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "작업명",
                        name: "col1",
                      },
                      model: {
                        value: _vm.data.col1,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col1", $$v)
                        },
                        expression: "data.col1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        type: "edit",
                        comboItems: _vm.comboItems1,
                        itemText: "codeName",
                        itemValue: "code",
                        name: "col4",
                        label: "작업유형",
                      },
                      model: {
                        value: _vm.data.col4,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col4", $$v)
                        },
                        expression: "data.col4",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-plant", {
                      attrs: { editable: false, name: "plantCd" },
                      model: {
                        value: _vm.data.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "plantCd", $$v)
                        },
                        expression: "data.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-equip", {
                      attrs: {
                        required: true,
                        label: "대상설비",
                        name: "col6",
                      },
                      model: {
                        value: _vm.data.col6,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col6", $$v)
                        },
                        expression: "data.col6",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-dept", {
                      attrs: {
                        type: "edit",
                        editable: _vm.editable,
                        label: "위치",
                        name: "col5",
                        isFirstValue: false,
                      },
                      model: {
                        value: _vm.data.col5,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col5", $$v)
                        },
                        expression: "data.col5",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-dept", {
                      attrs: {
                        type: "edit",
                        editable: false,
                        label: "작업요청부서",
                        name: "col3",
                      },
                      model: {
                        value: _vm.data.col3,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col3", $$v)
                        },
                        expression: "data.col3",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: false,
                        label: "작업요청자",
                        name: "col19",
                      },
                      model: {
                        value: _vm.data.col19,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col19", $$v)
                        },
                        expression: "data.col19",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: false,
                        default: "today",
                        label: "요청 종료일",
                        name: "col2",
                      },
                      model: {
                        value: _vm.data.col2,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col2", $$v)
                        },
                        expression: "data.col2",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        isFlag: true,
                        label: "안전작업허가서 발행여부",
                        name: "col99",
                      },
                      model: {
                        value: _vm.data.col99,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col99", $$v)
                        },
                        expression: "data.col99",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 4,
                        label: "작업요청 세부사항",
                        name: "col7",
                      },
                      model: {
                        value: _vm.data.col7,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col7", $$v)
                        },
                        expression: "data.col7",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    staticStyle: { "padding-top": "13px !important" },
                  },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                        label: "대상설비 도면 등 첨부파일",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c(
            "c-mobile-table",
            {
              ref: "grid",
              attrs: {
                title: "세부 작업계획",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                gridHeightAuto: true,
                isTitle: true,
                editable: _vm.editable && !_vm.disabled,
                hideBottom: true,
                isExcelDown: false,
                filtering: false,
                isFullScreen: false,
                columnSetting: false,
                selection: "multiple",
                rowKey: "userId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", { attrs: { label: "", icon: "add" } })
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable&&!disabled",
                          },
                        ],
                        attrs: { label: "안전작업표준추가", icon: "add" },
                        on: { btnClicked: _vm.openSop },
                      }),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", { attrs: { label: "", icon: "remove" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              staticStyle: { height: "252px !important" },
              attrs: { title: "작업 스케줄정보" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: _vm.editable,
                        type: "datetime",
                        label: "목표 시작일시",
                        name: "col002",
                        minuteStep: 10,
                      },
                      model: {
                        value: _vm.data.col002,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col002", $$v)
                        },
                        expression: "data.col002",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: _vm.editable,
                        type: "datetime",
                        label: "목표 완료일시",
                        name: "col003",
                        minuteStep: 10,
                      },
                      model: {
                        value: _vm.data.col003,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col003", $$v)
                        },
                        expression: "data.col003",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: false,
                        type: "datetime",
                        label: "실제 시작일시",
                        name: "col004",
                        minuteStep: 10,
                      },
                      model: {
                        value: _vm.data.col004,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col004", $$v)
                        },
                        expression: "data.col004",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: false,
                        type: "datetime",
                        label: "실제 완료일시",
                        name: "col005",
                        minuteStep: 10,
                      },
                      model: {
                        value: _vm.data.col005,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "col005", $$v)
                        },
                        expression: "data.col005",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }